import React from "react";

//components
import {
	Text,
	CloudinaryImage,
	Separator,
} from "@website-builder/ui/shared/elements";

//styles
import { AlumniCardWrapper } from "./styles";
const AlumniCard = ({ details, isDarkBg }) => {
	const { title, subTitle, description, avatar } = details;

	return (
		<AlumniCardWrapper>
			<Separator
				style={{ margin: "1px" }}
				darkBg={isDarkBg}
				color={"var(--secondary-gray-medium)"}
			/>
			<div className="alumni-user-details">
				<Text
					variant="paragraph_M_bold"
					color={{
						base: "primary",
						color: "neutral-nightshade",
						shade: "800",
					}}
				>
					{title}
				</Text>
				<Text
					variant="paragraph_M"
					color={{
						base: "primary",
						color: "neutral-nightshade",
						shade: "600",
					}}
				>
					{subTitle}
				</Text>
				<Text
					className="color-gray"
					variant="paragraph_M"
					tag="div"
					color={{
						base: "secondary",
						color: "gray",
						shade: "dark",
					}}
				>
					{description}
				</Text>
			</div>
			<div className="alumni-company-logo-wrapper">
				<CloudinaryImage
					className="alumni-company-logo"
					url={avatar?.image}
					quality="auto"
					lazyload
					fetchFormat="auto"
					dynamic
				/>
			</div>
		</AlumniCardWrapper>
	);
};

export default AlumniCard;
