import React, { useEffect, useRef, useState } from "react";
import { richTextResolver } from "@website-builder/utilities/utils/TransformerHelpers.js";

//components
import {
	Text,
	Grid,
	Col,
	LineGraphic,
	VideoPlayer,
	Slider,
	Modal,
	Icon,
	Link,
	VideoElement,
} from "@website-builder/ui/shared/elements";

//styles
import { VideoTestimonialWrapper, VideoWrapper, IconWrapper } from "./styles";

//components
import AlumniCard from "./AlumniCard";
import StudentOutcomes from "./StudentOutcomes";
import { handleCTAClick } from "@website-builder/utilities/utils/DesignUtils.js";

const VideoFeatureTestimonial = ({
	label,
	headline,
	content,
	alignVideoOn,
	icon,
	isDarkBg,
	showCarouselArrows,
	showLineGraphic,
	globalVariableWithURL,
	pageSection,
}) => {
	// slider reference ro handle slide next and prev
	const sliderRef = useRef();
	const gotoNext = () => {
		sliderRef.current.slickNext();
	};
	const goToPrev = () => {
		sliderRef.current.slickPrev();
	};
	// states
	const [openVideoModal, setOpenVideoModal] = useState(false);
	const [currentVideoMedia, setCurrentVideoMedia] = useState("");
	const [currentContent, setCurrentContent] = useState({});
	const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

	useEffect(() => {
		setCurrentContent(
			Array.isArray(content?.content) ? content?.content[0] : content?.content,
		);
	}, []);

	const otherSettings = {
		dots: true,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		speed: 1000,
		arrows: showCarouselArrows,
		afterChange: (current) => {
			!content.isStaticContent && setCurrentContent(content?.content[current]);
			setCurrentVideoIndex(current);
		},
	};

	const responsive = [
		{
			breakpoint: 2000,
			settings: {
				dots: true,
				infinite: true,
				slidesToShow: 1,
				slidesToScroll: 1,
				autoplay: false,
				speed: 500,
				arrows: false,
				appendDots: (dots) => renderCustomArrows(dots),
			},
		},
	];

	const renderCustomArrows = (dots) => {
		return (
			<ul>
				{/** This is adding custom previous and next button for the mobile view. For desktop inbuilt next/prev button is used.*/}
				{showCarouselArrows && (
					<li>
						<button
							onClick={goToPrev}
							className="slider-video slick-arrow slick-prev arrow-prev custom-arrow"
						>
							Prev
						</button>
					</li>
				)}
				{dots}
				{showCarouselArrows && (
					<li>
						<button
							onClick={gotoNext}
							className="slider-video slick-arrow slick-next arrow-prev custom-arrow"
						>
							Next
						</button>
					</li>
				)}
			</ul>
		);
	};

	// renders common content for all the slides
	const renderStaticTexts = () => {
		return (
			<div className="testimonial-heading-wrapper flex-column">
				<Text variant="label_M" style={{ color: label?.fontColor?.color }}>
					{label?.text}
				</Text>
				{icon ? (
					<IconWrapper>
						<Icon iconName={icon?.iconName} size={"32px"} />
					</IconWrapper>
				) : null}
				<Text
					variant="headline_3"
					tag="h2"
					color={{
						base: "primary",
						color: "neutral-nightshade",
						shade: "800",
					}}
				>
					{headline}
				</Text>
			</div>
		);
	};

	// renders different content for all the slides
	const DynamicContent = ({
		description: desc,
		studentOutcomes,
		cta,
		alumniCard,
	}) => {
		const description = richTextResolver(desc, globalVariableWithURL);
		return (
			<div className="dynamic-content flex-column">
				{description ? (
					<Text
						variant="paragraph_L"
						className="video-feature-description"
						color={
							!isDarkBg
								? {
										base: "primary",
										color: "neutral-nightshade",
										shade: "600",
									}
								: {
										base: "primary",
										color: "neutral",
										shade: "white",
									}
						}
						tag="div"
						dangerouslySetInnerHTML={{ __html: description }}
					/>
				) : null}
				{studentOutcomes ? (
					<StudentOutcomes
						studentOutcomes={studentOutcomes}
						isDarkBg={isDarkBg}
					/>
				) : null}
				{cta ? (
					<Link
						href={cta?.link?.link}
						target={cta?.link?.target}
						arrowCta
						darkBg={isDarkBg}
						onClick={(e) =>
							handleCTAClick(
								cta,
								{
									page_section: pageSection,
									click_text: cta?.linkText,
									redirect_to: cta?.link?.link,
								},
								{},
								e,
							)
						}
						disabled={content?.cta?.disabled}
					>
						<Text
							variant="paragraph_M_bold"
							className={(isDarkBg && "color-sp1") || ""}
							color={{
								base: "secondary",
								color: "purple",
								shade: "300",
							}}
						>
							{cta?.linkText}
						</Text>
					</Link>
				) : null}
				{alumniCard ? (
					<AlumniCard details={alumniCard} isDarkBg={isDarkBg} />
				) : null}
			</div>
		);
	};

	const renderTestimonialDetails = (content) => {
		return (
			<div className="testimonial-details-wrapper flex-column">
				{renderStaticTexts()}
				<DynamicContent {...content} />
			</div>
		);
	};

	const renderVideoSlider = () => {
		return (
			<VideoWrapper>
				{!Array.isArray(content?.videos) ? (
					<VideoPlayer
						className="testimonial-video-player"
						videoImageUrl={content?.videos?.videoImage?.image}
						altTag={content?.videos?.videoImage?.altTag}
						mediaUrl={content?.videos?.videoUrl?.url}
						isDarkBg={true}
					/>
				) : (
					<Slider
						darkBg={isDarkBg}
						reference={sliderRef}
						className="testimonial-slider"
						responsive={responsive}
						otherSettings={{
							...otherSettings,
							dotsColor: isDarkBg
								? "var(--primary-green-400)"
								: "var(--primary-forest-400)",
						}}
						carouselData={content?.videos?.map(
							({ videoImage = {}, videoUrl = {} }, index) => {
								return (
									<VideoPlayer
										onClick={(mediaUrl) => {
											setCurrentVideoMedia(mediaUrl);
											setOpenVideoModal(true);
										}}
										className="testimonial-video-player"
										isDarkBg={true}
										haveModal={false}
										videoImageUrl={videoImage?.image}
										altTag={videoImage?.altTag}
										mediaUrl={videoUrl?.url}
										key={index}
										isNonActive={currentVideoIndex !== index}
									/>
								);
							},
						)}
					/>
				)}

				<Modal
					className="video-modal"
					show={openVideoModal}
					disableBackdropClick={true}
					onClose={() => setOpenVideoModal(false)}
					media
				>
					{openVideoModal && <VideoElement url={currentVideoMedia} />}
				</Modal>
			</VideoWrapper>
		);
	};

	const renderTestimonialContent = () => {
		const contentColumn = (
			<Col
				span={4}
				mdSpan={6}
				vAlign={
					!currentContent?.studentOutcomes && !currentContent?.alumniCard
						? "center"
						: "start"
				}
			>
				{renderTestimonialDetails(currentContent)}
			</Col>
		);
		const videoColumn = (
			<Col span={7} mdSpan={6} vAlign={"center"}>
				{renderVideoSlider()}
			</Col>
		);

		// defines the order of view (videos/content) from left to right
		const orderOfDisplay = [contentColumn];

		alignVideoOn === "left"
			? orderOfDisplay.unshift(videoColumn)
			: orderOfDisplay.push(videoColumn);

		return (
			<>
				{orderOfDisplay[0]}
				<Col span={1} mdSpan={6}></Col>
				{orderOfDisplay[1]}
			</>
		);
	};

	return (
		<VideoTestimonialWrapper
			isDarkBg={isDarkBg}
			alignVideoOn={alignVideoOn}
			isStatic={content?.isStaticContent}
			className="video-feature-testimonial"
		>
			{showLineGraphic && (
				<LineGraphic
					className="video-player-line-graphic"
					stroke={
						isDarkBg
							? "var(--primary-green-400)"
							: "var(--secondary-pencil-200)"
					}
					size="30vw"
					top="0"
					left={alignVideoOn === "left" ? "0" : undefined}
					right={alignVideoOn === "right" ? "0" : undefined}
					x="10%"
					y="10%"
					flip={alignVideoOn === "right"}
					rotate={alignVideoOn === "right" ? "190" : "180"}
					line="single_loop_with_curl"
				/>
			)}
			<Grid>{renderTestimonialContent()}</Grid>
		</VideoTestimonialWrapper>
	);
};

export default VideoFeatureTestimonial;
