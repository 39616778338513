import React from "react";

//components
import {
	Text,
	CloudinaryImage,
	Separator,
} from "@website-builder/ui/shared/elements";

//styles
import { StudentOutcomeWrapper } from "./styles";

const StudentOutcomes = ({ isDarkBg, studentOutcomes }) => {
	return (
		<StudentOutcomeWrapper isDarkBg={isDarkBg}>
			<div className="outcome-items-wrapper">
				{studentOutcomes.map(({ name, feedback, position, avatar }, index) => {
					return (
						<React.Fragment key={index}>
							<div className="outcome-item-container" key={`${name}+${index}`}>
								<div className="avatar-wrapper">
									<CloudinaryImage
										className="avatar"
										alt={avatar?.[0]?.image?.altTag}
										url={avatar?.[0]?.image?.image}
										quality="auto"
										lazyload
										fetchFormat="auto"
									/>
								</div>
								<div className="outcome-details-wrapper flex05">
									<Text
										variant="paragraph_S"
										color={{
											base: "primary",
											color: "neutral-nightshade",
											shade: "600",
										}}
									>
										{feedback}
									</Text>
									<div className="outcome-details-sub-wrapper flex05">
										<Text
											variant="label_M"
											color={{
												base: "primary",
												color: "neutral-nightshade",
												shade: "600",
											}}
										>
											{name}
										</Text>
										<Text
											variant="label_M"
											color={{
												base: "primary",
												color: "neutral-nightshade",
												shade: "600",
											}}
										>
											&bull;
										</Text>

										<Text
											variant="label_M"
											color={{
												base: "primary",
												color: "neutral-nightshade",
												shade: "600",
											}}
										>
											{position}
										</Text>
									</div>
								</div>
							</div>
							{studentOutcomes?.length !== index + 1 && (
								<Separator
									style={{ margin: "1rem 0" }}
									darkBg={isDarkBg}
									color={"var(--secondary-gray-medium)"}
								/>
							)}
						</React.Fragment>
					);
				})}
			</div>
		</StudentOutcomeWrapper>
	);
};

export default StudentOutcomes;
