import styled from "styled-components";
import {
	TABLET_M_BP,
	MOBILE_L_BP,
	MOBILE_SM_BP,
} from "@website-builder/utilities/constants/style-constants/screen-sizes.js";

export const IconWrapper = styled.figure`
	margin: 0;
	display: inline-block;
	line-height: 0;
	border-radius: 8px;
`;
export const AlumniCardWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	.alumni-user-details {
		display: flex;
		flex-direction: column;
		gap: 0.25rem;
	}
	.alumni-company-logo-wrapper {
		margin-top: 0.5rem;
		width: 4rem;
		max-height: 2rem;
	}
	.alumni-company-logo {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
	@media only screen and (max-width: ${TABLET_M_BP}px) {
		.alumni-company-logo-wrapper {
			margin-bottom: 1rem;
		}
	}
`;

export const VideoTestimonialWrapper = styled.div`
	position: relative;
	overflow: hidden;
	padding: 5rem 0;
	.testimonial-slider {
		padding: 0;
	}
	.testimonial-slider img {
		border-radius: 0.5rem;
	}

	.slider-video {
		top: 15px;
		display: block !important;
		width: 2.5rem !important;
		height: 2.5rem !important;
		border: ${(props) =>
			`1px solid ${
				props.isDarkBg
					? "var(--primary-neutral-white)"
					: " var(--primary-neutral-nightshade-800)"
			} !important`};
	}
	.slider-video.slick-next {
		right: -40px;
	}
	.slider-video.slick-prev {
		left: -30px;
	}
	.testimonial-slider .slick-dots li button:before {
		font-size: 8px;
	}
	hr {
		background-color: var(--primary-neutral-white);
	}
	${(props) =>
		props.isDarkBg &&
		`p,h3 {
    color:var(--primary-neutral-white) !important;
  }
  .color-gray{
     color:var(--secondary-gray-medium) !important;
  }
  .color-sp1{
     color:var(--secondary-purple-100) !important;
  }
  `}
	${(props) =>
		!props.isStatic &&
		`
  .dynamic-content {
    position: relative;
    z-index: 1;
    animation: fadeInVideoContent 1s ease-in;
    @keyframes fadeInVideoContent {
      0% {
        opacity: 0.5;
      }
      100% {     
        opacity: 1;
      }
    }
  }
  `}
 
  .testimonial-video-player > img {
		border-radius: 0.5rem;
	}
	.dynamic-content {
		gap: 1.5rem;
	}
	@media only screen and (max-width: ${TABLET_M_BP}px) {
		padding: 2.5rem 0;
		padding-bottom: 5rem;
		.video-player-line-graphic {
			display: none;
		}
	}
	.flex-column {
		display: flex;
		flex-direction: column;
	}
	.testimonial-details-wrapper {
		margin-top: ${(props) => `${props.alignVideoOn === "left" ? "2rem" : "0"}`};
	}

	.testimonial-heading-wrapper {
		gap: 1rem;
	}
	.video-feature-description {
		margin-top: 1rem;
	}
`;
export const VideoWrapper = styled.div`
	height: 100%;
	border-radius: 8px;
	img {
		object-fit: cover;
		width: 100%;
		aspect-ratio: 16/9;
	}

	.video-modal {
		width: 70%;
		height: fit-content;
		transform: translate(-50%, -50%);
		top: 50%;
		left: 50%;
	}
	& .media-container {
		position: relative;
	}
	& .video-button {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -60%);
		transform: translateX(-50%) translateY(-50%);
		z-index: 2;
	}
	@media only screen and (max-width: ${TABLET_M_BP}px) {
		.video-modal {
			width: calc(100% - 10rem);
			height: auto;
			padding: 80px 0;
		}
		.video-modal .closeButtonWrapper {
			right: 0;
		}
		img {
			height: 50vw;
			max-height: unset;
		}
	}
	@media only screen and (max-width: ${MOBILE_L_BP}px) {
		img {
			height: 55vw;
		}
	}
	@media screen and (orientation: landscape) and (min-device-width: ${MOBILE_SM_BP}px) and (max-device-width: ${TABLET_M_BP}px) {
		.video-modal {
			width: calc(100% - 15rem);
			height: auto;
			padding: 80px 10%;
		}
	}
`;
export const StudentOutcomeWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	margin: 0.5rem 0;
	.flex05 {
		display: flex;
		gap: 0.5rem;
	}
	.outcome-details-wrapper {
		flex-direction: column;
	}
	.outcome-items-wrapper {
		margin: 0.5rem 0;
	}
	.outcome-item-container {
		display: flex;
		gap: 1.25rem;
	}
	.avatar-wrapper {
		width: 48px;
		height: 48px;
	}
	.outcome-details-wrapper {
		width: 80%;
	}
	.outcome-details-sub-wrapper {
		display: flex;
	}
	${(props) =>
		props.isDarkBg &&
		` .outcome-details-sub-wrapper label{
      color:var(--primary-neutral-white) !important;
    }
  `}
	.avatar {
		object-fit: cover;
		width: 100%;
		border-radius: 50%;
		max-height: 48px;
	}
`;
